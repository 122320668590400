import React from "react";

function AboutContact () {
    return (
    <div className="flex-centered">
        <div className="about-contact-container">
            <p className="text-title about-title">
                CONTACT<br/></p>
            <p className="text-title">INFO@CARMAFLORES.COM<br/><br/>
            </p>
            <p className="text-title about-title">
                ABOUT<br/>
            </p>
            <p className="about-text">
                Carma Flores is a fashion stylist and creative consultant with 7 years of experience working with a wide myriad of clients in the fashion and creative worlds. She has collaborated with fashion brands such as Richardson Magazine, musicians and underground talent, including, but not limited to, Eartheater, Isabella Lovestory, and Caleb Giles. Flores has also lent her expertise to cultural research and creative direction for artists and brands such as Frank Ocean, Nike, Spotify, and Cash App.<br/><br/>

                As a first-generation Mexican-Dominican New Yorker, Flores draws her inspiration and energy from the NYC creative community. She has assisted renowned stylists like Lauren Matos and Becky Akinyode on celebrity clients such as Miguel, Charlie Puth, Bad Bunny, and Wiz Khalifa, and over time has honed her signature perspective and aesthetic.
            </p>
        </div>
    </div>
    )
}

export default AboutContact